$("a[href^='#']").on('click', function(e) {
    e.preventDefault();

    var hash = this.hash;

    $('.nav-link').removeClass('active');
    $(this).addClass('active');

    // animate
    $('html, body').animate({
        scrollTop: $(hash).offset().top - 59
    }, 500)
});

$(function() {
    let brandSwitchTimer = null;
    let targetHeaderSize = null;

    function applyBrandSize() {
        const navBrand = $(".navbar-brand-group");
        const isSmall = navBrand.find('.large').hasClass('hidden');
        const shouldSmall = $(document).scrollTop() > 100;

        if(targetHeaderSize !== shouldSmall && isSmall !== shouldSmall) {
            clearTimeout(brandSwitchTimer);

            targetHeaderSize = shouldSmall;
            if (shouldSmall) {
                navBrand.find('.large').addClass('hidden');

                brandSwitchTimer = setTimeout(function () {
                    navBrand.find('.small').removeClass('hidden');

                    targetHeaderSize = null;
                }, 200);
            } else {
                navBrand.find('.small').addClass('hidden');

                brandSwitchTimer = setTimeout(function () {
                    navBrand.find('.large').removeClass('hidden');

                    targetHeaderSize = null;
                }, 200);
            }
        }
    }

    applyBrandSize();

    $(window).on('scroll', function() {
        if ($(window).width() >= 992) {
            applyBrandSize();
        }
    });

    const navbarCollapse = $(".navbar .navbar-collapse");

    navbarCollapse.on("show.bs.collapse", function() {
        $(".navbar-brand").addClass('menu-open');
    });

    navbarCollapse.on("hide.bs.collapse", function() {
        $(".navbar-brand").removeClass('menu-open');
    });

    navbarCollapse.find(".nav-item > a").on('click', function() {
        navbarCollapse.collapse('hide');
    });
});

$(function () {
    AOS.init();
});
